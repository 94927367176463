import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Adjust the path accordingly
import Spinner from './Spinner';

const UserAuth = ({ children }) => {
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();
	if (loading) {
		// Loading spinner or other UI can be added here
		return <div>Loading...</div>;
	}

	return React.cloneElement(children, { user });
};

export default UserAuth;
