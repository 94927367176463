import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Signin from './components/Signin';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import { auth } from './components/firebase';
import Login from './components/Login';
import UserAuth from './components/UserAuth';
import MicrosoftWordPage from './components/MicrosoftWordPage';
import ResumeBuilder from './components/ResumeBuilder';
import CollapsableItems from './components/CollapsableItems';
import ExportResume from './components/ExportResume';
function App() {
	return (
		<div className=''>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/About' element={<About />} />
					<Route path='/Services' element={<Services />} />
					<Route path='/Contact' element={<Contact />} />
					<Route path='/Signin' element={<Signin />} />
					<Route path='/Register' element={<Register />} />
					<Route
						path='/Dashboard'
						element={
							<UserAuth>
								<Dashboard />
							</UserAuth>
						}
					/>
					<Route
						path='/ResumeBuilder'
						element={
							<UserAuth>
								<ResumeBuilder />
							</UserAuth>
						}
					/>
					<Route
						path='/ResumeBuilder'
						element={
							<UserAuth>
								<ExportResume />
							</UserAuth>
						}
					/>
					<Route path='/Login' element={<Login />} />
					<Route path='/CollapsableItems' element={<CollapsableItems />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
