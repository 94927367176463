import { React, useState } from 'react';
import New from './New';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Login() {
	const [value, setValue] = useState({
		email: '',
		password: '',
	});
	const [errorMsg, setErrorMsg] = useState('');
	const [submitButtonDisable, setSubmitButton] = useState(false);
	const navigate = useNavigate();

	const handleSubmission = () => {
		if (!value.email || !value.password) {
			setErrorMsg('Please fill in all the fields');
			return;
		}
		setErrorMsg('');
		setSubmitButton(true);
		signInWithEmailAndPassword(auth, value.email, value.password)
			.then(async (res) => {
				setSubmitButton(false);
				navigate('/Dashboard');
				console.log(res);
			})
			.catch((err) => {
				setSubmitButton(false);
				setErrorMsg(err.message);
				console.log('Error:', err.message);
			});
	};
	return (
		<div>
			<div className='py-[150px] flex items-center justify-center '>
				<div>
					<h1 className='text-lg  text-center text-indigo-800'>
						Sign in to your account
					</h1>
					<div className='flex flex-col mt-5 gap-4 px-5'>
						<input
							className='border w-[350px] px-3 py-4 rounded-md text-sm font-light text-gray-600 focus:outline-none bg-blue-50 focus:border-indigo-900'
							type='text'
							name='usremail'
							placeholder='Email Address*'
							onChange={(event) =>
								setValue((prev) => ({ ...prev, email: event.target.value }))
							}
						/>
						<input
							className='border w-[350px] px-3 py-4 rounded-md text-sm font-light text-gray-600 focus:border-indigo-900 focus:outline-none bg-blue-50'
							type='password'
							name='usrpasswd'
							placeholder='Password*'
							onChange={(event) =>
								setValue((prev) => ({ ...prev, password: event.target.value }))
							}
						/>

						<div className='mt-1'>
							<button
								onClick={handleSubmission}
								disabled={submitButtonDisable}
								className={`rounded-lg md:rounded-md py-4 text-sm ${
									submitButtonDisable
										? 'bg-gray-400 text-white w-[350px] py-4 text-sm'
										: 'bg-gradient-to-r from-indigo-700 to-indigo-900 text-white w-[350px] py-4 text-sm'
								}`}
							>
								Sign In
							</button>
							<div className='flex flex-col items-center justify-center'>
								<div className='text-sm mt-4'>
									<Link className='text-indigo-800 font-semibold' to='/Login'>
										Forgot your password?
									</Link>
								</div>
								<div className='text-sm mt-2'>
									First time here?{' '}
									<Link
										className='text-indigo-800 font-semibold'
										to='/Register'
									>
										Create your account
									</Link>
								</div>
							</div>
							<div className='flex items-center justify-center px'>
								<h1 className='text-red-500 text-sm mt-2'>{errorMsg}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<New />
		</div>
	);
}

export default Login;
