// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import {
	getFirestore,
	collection,
	doc,
	setDoc,
	addDoc,
	getDoc,
	getDocs,
	updateDoc,
	onSnapshot,
	deleteDoc,
} from 'firebase/firestore';
const firebaseConfig = {
	apiKey: 'AIzaSyDEUlC_71rT9t8N3e2uZBOlQRSG79lWZmM',
	authDomain: 'enhancid.firebaseapp.com',
	databaseURL:
		'https://enhancid-default-rtdb.asia-southeast1.firebasedatabase.app',
	projectId: 'enhancid',
	storageBucket: 'enhancid.appspot.com',
	messagingSenderId: '681426435574',
	appId: '1:681426435574:web:a6f18f82c50e605f575bd7',
	measurementId: 'G-FSB8R3LDBF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export {
	app,
	auth,
	db,
	collection,
	doc,
	setDoc,
	addDoc,
	getDoc,
	getDocs,
	updateDoc,
	onSnapshot,
	deleteDoc,
};
