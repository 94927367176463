import React, { useState, useEffect } from 'react';
import UserAuth from './UserAuth';
import New from './New';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import {
	db,
	collection,
	addDoc,
	doc,
	setDoc,
	getDoc,
	updateDoc,
	onSnapshot,
} from './firebase'; // Import auth from firebase.js
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import CollapsableItems from './CollapsableItems';
import BottomNavBar from './BottomNavBar';

function ResumeBuilder({ user }) {
	const navigate = useNavigate();

	console.log('ResumeBuilder Page', user.uid, user.displayName);
	const [resumeCount, setResumeCount] = useState(0);

	const [formData, setFormData] = useState({
		resumeName: '',
		jobTitle: '',
		userName: user.displayName || '',
		phone: '',
		email: '',
		location: '',
		linkedin: '',
		// Add more fields as needed
	});

	useEffect(() => {
		if (!user) {
			// Redirect or handle the case when the user is not logged in
			navigate('/Login');
			console.log('ResumeBuilder - User not logged in');
		}
	}, [user, navigate]);
	// Use the user's UID as the document ID
	// await setDoc(userDocRef, {
	// 	displayName: 'Sample User',
	// 	email: 'user@example.com',
	// });

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	useEffect(() => {
		const userEmail = user.email; // Replace with the actual user's email

		const userDocRef = doc(db, 'userBase', userEmail);

		const unsubscribe = onSnapshot(userDocRef, (doc) => {
			if (doc.exists()) {
				const userData = doc.data();
				setResumeCount(userData.resumeCount || 0);
			} else {
				console.log('User document does not exist');
			}
		});

		// Clean up the subscription when the component unmounts
		return () => unsubscribe();
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!formData.resumeName) {
			// Display an error toast
			toast.error('Resume name can not be empty!', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
				style: {
					width: '350px',
					marginTop: '50px',
					marginLeft: '20px', // Add top margin
				},
			});
			return;
		}
		// Increment resumeCount by 1
		const updatedResumeCount = resumeCount + 1;

		// Update resumeCount in Firestore
		const userEmail = user.email; // Replace with the actual user's email
		const userDocRef = doc(db, 'userBase', userEmail);

		await setDoc(
			userDocRef,
			{ resumeCount: updatedResumeCount },
			{ merge: true }
		);

		// Store the form data in Firestore with the new resumeCount
		const resumesCollectionRef = collection(userDocRef, 'resumes');
		const currentDate = new Date();
		await addDoc(resumesCollectionRef, {
			resumeId: updatedResumeCount,
			...formData,
			createdDate: currentDate,
			lastEditedDate: currentDate,
		});

		// Reset the form data or perform any other necessary actions
		setFormData({
			resumeName: '',
			jobTitle: '',
			userName: user.displayName || '',
			phone: '',
			email: '',
			location: '',
			linkedin: '',
			// Reset other fields as needed
		});

		// Optionally, you can update the local resumeCount state to reflect the change
		setResumeCount(updatedResumeCount);
	};
	const handleExternalSubmit = () => {
		// Manually trigger form submission
		handleSubmit({ preventDefault: () => {} }); // Pass an empty event object
	};

	return (
		<div>
			<New />
			<div className='mx-auto mt-[52px] py-4 mb-4 bg-[#fafbfd]'>
				<div className='flex item-center bg-gray-200 w-full py-5 px-4'>
					<div className='flex  justify-center w-8 h-8 bg-indigo-300 rounded-full'>
						<FontAwesomeIcon
							className='mt-2'
							icon={faFileLines}
							style={{ color: '#856be6' }}
						/>
					</div>
					<p className='flex px-2 items-center'>New Resume</p>
				</div>
				<div>
					<div className='flex items-center justify-between px-6 mt-4 '>
						<div>
							<p className='text-lg '>Header</p>
							<p className='font-light text-[12px] mt-2'>Personal Details</p>
						</div>

						<div className='flex items-center gap-4'>
							<button className='rounded-md bg-gradient-to-r from-green-600 to-green-700 text-white px-3 py-2 text-sm'>
								<FontAwesomeIcon
									className='h-4 w-4'
									icon={faArrowUpFromBracket}
								/>{' '}
							</button>
							<FontAwesomeIcon
								className='w-[70px] h-[70px] text-gray-300'
								icon={faCircleUser}
							/>
						</div>
					</div>
					<div className='px-6'>
						<form onSubmit={handleSubmit}>
							<div className='mt-2'>
								<label
									htmlFor='resumeName'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Resume Name
								</label>
								<input
									type='text'
									id='resumeName'
									name='resumeName'
									value={formData.resumeName}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder='Enter your desired resume name'
								/>
								<label
									htmlFor='jobTitle'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Job Title:
								</label>
								<input
									type='text'
									id='jobTitle'
									name='jobTitle'
									value={formData.jobTitle}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder='The role you are applying for'
								/>

								<label
									htmlFor='userName'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Your Name:
								</label>
								<input
									type='text'
									id='userName'
									name='userName'
									value={formData.userName}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder=''
								/>
								<label
									htmlFor='phone'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Phone
								</label>
								<input
									type='text'
									id='phone'
									name='phone'
									value={formData.phone}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder='Phone'
								/>
								<label
									htmlFor='email'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Email
								</label>
								<input
									type='text'
									id='email'
									name='email'
									value={formData.email}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder='Email'
								/>
								<label
									htmlFor='location'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Location
								</label>
								<input
									type='text'
									id='location'
									name='location'
									value={formData.location}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder='Location'
								/>
								<label
									htmlFor='linkedin'
									className='font-light text-gray-500 text-[12px] mb-2'
								>
									Linkedin/Portfolio (URL)
								</label>
								<input
									type='text'
									id='linkedin'
									name='linkedin'
									value={formData.linkedin}
									onChange={handleChange}
									className='w-full px-3 py-3 mt-2 mb-2 rounded-md text-sm font-light text-gray-600 border border-gray-300 focus:outline-none bg-white'
									placeholder='Linkedin/Portfolio'
								/>
							</div>
						</form>
					</div>
					<CollapsableItems />
				</div>
			</div>
			<nav className='flex fixed bottom-0 w-full items-center justify-center px-6 gap-2 py-4 bg-gray-200'>
				<button
					onClick={handleExternalSubmit}
					className='rounded-md shadow-2xl bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-2 text-sm'
				>
					Save Resume
				</button>
				<Link to='/Dashboard'></Link>
				<button className='rounded-md bg-[#fc546c] text-white px-6 py-2 text-sm'>
					<Link to='/Dashboard'>Discard Resume</Link>
				</button>
			</nav>
			<BottomNavBar />
			<div className='mt-24'></div>
			<ToastContainer />
			{/* <New /> */}
		</div>
	);
}

export default ResumeBuilder;
