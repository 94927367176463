import React from 'react';
import New from './New';

function Services() {
	return (
		<div>
			<div className='py-[400px] flex items-center justify-center text-4xl'>
				{' '}
				Page Coming Soon!
			</div>
			<New />
		</div>
	);
}

export default Services;

// https://pngimg.com/uploads/letter_a/letter_a_PNG31.png
// https://st.depositphotos.com/1001311/3411/i/950/depositphotos_34119555-stock-photo-letter-b-from-gold-solid.jpg
// https://pngimg.com/uploads/letter_c/letter_c_PNG27.png
