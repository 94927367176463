import React from 'react';
import New from './New';

function Contact() {
	return (
		<div>
			<div className='py-[400px] flex items-center justify-center text-4xl'>
				{' '}
				Page Coming Soon!
			</div>
			<New />
		</div>
	);
}

export default Contact;
