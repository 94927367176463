import React, { useState } from 'react';

const BottomNavBar = () => {
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<nav className='bg-gray-200 p-4 mt-[-20px]'>
			<div className='flex items-center justify-between'>
				<div>
					<span className='font-bold text-lg'>Other Sections</span>
				</div>
				<div className='xl:hidden'>
					<button
						onClick={toggleMobileMenu}
						className='text-gray-400 focus:outline-none transition-transform duration-300 ease-in-out transform'
					>
						{isMobileMenuOpen ? (
							// Displaying up arrow when the menu is open
							<svg
								className='w-6 h-6'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									stroke-linecap='round'
									stroke-linejoin='round'
									stroke-width='2'
									d='M5 15l7-7 7 7'
								></path>
							</svg>
						) : (
							// Displaying down arrow when the menu is closed
							<svg
								className='w-6 h-6'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									stroke-linecap='round'
									stroke-linejoin='round'
									stroke-width='2'
									d='M19 9l-7 7-7-7'
								></path>
							</svg>
						)}
					</button>
				</div>
			</div>
			{/* Mobile Menu */}
			<div
				className={`lg:hidden transition-all duration-100 ease-in-out ${
					isMobileMenuOpen ? 'h-auto' : 'h-0'
				} overflow-hidden mt-2`}
			>
				<a href='#' className='block py-2'>
					Education
				</a>
				<a href='#' className='block py-2'>
					Experience
				</a>
				<a href='#' className='block py-2'>
					Summary
				</a>
				<a href='#' className='block py-2'>
					Skills
				</a>
				<a href='#' className='block py-2'>
					Education
				</a>
				<a href='#' className='block py-2'>
					Experience
				</a>
				<a href='#' className='block py-2'>
					Summary
				</a>
				<a href='#' className='block py-2'>
					Skills
				</a>
			</div>
		</nav>
	);
};

export default BottomNavBar;
