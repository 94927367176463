import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase';

import { useNavigate } from 'react-router-dom';

export const New = () => {
	const [user, setUser] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setUser(user);
		});

		// Clean up subscription on unmount
		return () => unsubscribe();
	}, []);

	const toggleSignInOut = () => {
		const auth = getAuth();
		const currentUser = auth.currentUser;
		console.log('current user:', currentUser);
		if (currentUser) {
			// User is signed in, sign out
			signOut(auth)
				.then(() => {
					// Sign-out successful.
					navigate('/');
					console.log('User signed out');
				})
				.catch((error) => {
					// An error happened.
					console.error('Error signing out:', error);
				});
		} else {
			// User is signed out, sign in
			// Implement your sign-in logic here
		}
	};

	let [open, setOpen] = useState(false);

	return (
		<div className=' w-full fixed top-0 left-0'>
			<div className='md:flex items-center justify-between gap-4 py-4 md:px-10 px-3 lg:px-10 bg-gradient-to-r from-blue-50 to-blue-200 '>
				<div className='font-bold cursor-pointer flex items-center text-indigo-800'>
					<span className='font-slabo font-bold text-2xl mt-0 text-indigo-700 mr-1 pt-1 px-2'>
						<Link to='/'>#Resumed.in</Link>
					</span>
				</div>
				<div className='flex text-3xl absolute right-8 top-3 gap-4  md:hidden'>
					<div>
						<button className='rounded-3xl bg-gradient-to-r from-indigo-700 to-indigo-900 text-white font-light px-5 py-2 text-sm'>
							<Link to='/Login' onClick={toggleSignInOut}>
								{user ? 'Sign Out' : 'SignIn'}
							</Link>
						</button>
					</div>
					<div
						className='mt-2 cursor-pointer md:hidden '
						onClick={() => setOpen(!open)}
					>
						{' '}
						<ion-icon name={open ? 'close' : 'menu'}></ion-icon>
					</div>
				</div>
				<ul
					className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static    bg-gradient-to-r from-blue-100 to-blue-200  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
						open ? 'top-20 opacity-100' : 'top-[-420px]'
					} md:opacity-100 opacity-0`}
				>
					{' '}
					<li className='md:ml-8 text-sm  md:my-0 my-7 text-indigo-800 hover:text-gray-900 duration-500'>
						<NavLink to='/' isActive={location.pathname === '/'}>
							Home
						</NavLink>
					</li>
					{/* <li className='md:ml-8 text-sm  md:my-0 my-2 text-indigo-800 hover:text-gray-900 duration-500'>
						<Link to='/'>Home</Link>
					</li> */}
					<li className='md:ml-8 text-sm  md:my-0 my-7 text-indigo-800 hover:text-gray-900 duration-500'>
						<NavLink to='/About' isActive={location.pathname === '/About'}>
							About
						</NavLink>
					</li>
					<li className='md:ml-8 text-sm  md:my-0 my-7 text-indigo-800 hover:text-gray-900 duration-500'>
						<NavLink
							to='/Services'
							isActive={location.pathname === '/Services'}
						>
							Services
						</NavLink>
					</li>
					<li className='md:ml-8 text-sm  md:my-0 my-7 text-indigo-800 hover:text-gray-900 duration-500'>
						<NavLink to='/Contact' isActive={location.pathname === '/Contact'}>
							Connect
						</NavLink>
					</li>
					{user && (
						<li className='md:ml-8 text-sm  md:my-0 my-7 text-green-600 hover:text-gray-900 duration-500'>
							<NavLink
								to='/Dashboard'
								isActive={location.pathname === '/Dashboard'}
							>
								My Documents
							</NavLink>
						</li>
					)}
					<li className='hidden xs:hidden md:block md:ml-8 text-sm md:my-0 my-7 text-indigo-800 '>
						<div className=''>
							<button className=' rounded-3xl bg-gradient-to-r from-indigo-700 to-indigo-900 text-white font-light px-5 py-2 text-sm'>
								<Link to='/Login' onClick={toggleSignInOut}>
									{user ? 'Sign Out' : 'Sign In'}
								</Link>
							</button>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

const NavLink = ({ to, isActive, children }) => {
	return (
		<Link
			to={to}
			className={`relative ${
				isActive ? 'text-green-700 font-bold' : 'text-gray-600'
			}`}
		>
			{children}
		</Link>
	);
};
export default New;
