import New from './New';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import UserAuth from '../components/UserAuth';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { onAuthStateChanged } from 'firebase/auth';
import {
	collection,
	doc,
	db,
	getDocs,
	getDoc,
	setDoc,
	onSnapshot,
	deleteDoc,
} from './firebase'; // Import firestore functions
import './Dashboard.css';

const DeleteConfirmation = ({ onConfirm, onCancel }) => {
	return (
		<div className='overlay '>
			<div className='confirmation-box px-2, py-2 bg-gray-200'>
				<p>Are you sure you want to delete?</p>
				<div className='flex items-center justify-center gap-4'>
					<button
						onClick={onConfirm}
						className='mt-4 flex items-center justity-center  rounded-md  bg-red-500 text-white px-3 py-1 text-sm'
					>
						Delete
					</button>
					<button
						onClick={onCancel}
						className='mt-4 flex items-center justity-center  rounded-md bg-gradient-to-r from-green-600 to-green-700 text-white px-3 py-1 text-sm'
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

function Dashboard({ user }) {
	console.log('Dashboard Page', user.uid, user.email);

	const navigate = useNavigate();
	if (!user) {
		// Redirect or handle the case when the user is not logged in

		navigate('/Login');
		console.log('Dashboard - User not logged in');
	}
	const location = useLocation();
	const isMediumScreen = useMediaQuery({ maxWidth: 767 });

	const [userData, setUserData] = useState({
		userName: '',
		resumeCount: 0,
		resumes: [],
	});

	const [resumeCount, setResumeCount] = useState(0);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedResumeId, setSelectedResumeId] = useState(null);

	const handleDelete = (resumeId) => {
		// Set the selected resumeId when the user clicks the delete button
		setSelectedResumeId(resumeId);
		setShowConfirmation(true);
		console.log('selectedResumeId', selectedResumeId);
	};

	const handleConfirmDelete = async () => {
		// Perform the delete operation with the selectedResumeId

		if (user.email && selectedResumeId) {
			try {
				// Construct the document reference path
				const userDocRef = doc(db, 'userBase', user.email);
				const resumeDocRef = doc(userDocRef, 'resumes', selectedResumeId);

				// Delete the document
				await deleteDoc(resumeDocRef);

				console.log(`Resume with ID ${selectedResumeId} deleted successfully.`);
				setShowConfirmation(false);
			} catch (error) {
				console.error('Error deleting resume:', error.message);
			}
		}
	};

	const handleCancel = () => {
		// Clear the selectedResumeId and hide the confirmation modal
		setSelectedResumeId(null);
		setShowConfirmation(false);
	};

	useEffect(() => {
		const userEmail = user.email; // Replace with the actual user's email

		const userDocRef = doc(db, 'userBase', userEmail);
		const resumesCollectionRef = collection(userDocRef, 'resumes');

		const unsubscribe = onSnapshot(resumesCollectionRef, (snapshot) => {
			const newData = snapshot.docs.map((resumeDoc) => ({
				id: resumeDoc.id,
				...resumeDoc.data(),
			}));

			setResumeCount(newData.length);

			setUserData((prevUserData) => ({
				...prevUserData,
				resumes: newData,
			}));

			// Move the console.log inside the setUserData callback
			console.log('ResumeCount', resumeCount);
		});

		// Clean up the subscription when the component unmounts
		return () => unsubscribe();
	}, [user.email]);

	const formatTimeDifference = (timestamp) => {
		const lastEditedDate = timestamp?.toDate(); // Convert Firestore timestamp to Date object
		if (lastEditedDate instanceof Date && !isNaN(lastEditedDate)) {
			const currentDate = new Date();
			const timeDifference = currentDate - lastEditedDate;

			// Define time intervals in milliseconds
			const minuteInterval = 60 * 1000;
			const hourInterval = 60 * minuteInterval;
			const dayInterval = 24 * hourInterval;

			if (timeDifference < minuteInterval) {
				return `${Math.round(timeDifference / 1000)} seconds ago`;
			} else if (timeDifference < hourInterval) {
				return `${Math.round(timeDifference / minuteInterval)} minutes ago`;
			} else if (timeDifference < dayInterval) {
				return `${Math.round(timeDifference / hourInterval)} hours ago`;
			} else {
				return lastEditedDate.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				});
			}
		}

		return 'Unknown';
	};
	console.log('Debug: resumeCount', resumeCount);

	return (
		<div className='px-6 md:px-12 bg-gradient-to-r from-blue-50 to-blue-200 h-screen'>
			<div>
				<div className=' py-6'>
					{' '}
					<div className='mt-[67px]'>
						<h2 className=' text-lg md:text-xl font-light'>
							<p className='text-gray-700'>
								Welcome back,&nbsp;
								<span className='text-indigo-700'>{user.displayName}</span>! You
								have{' '}
								<span className='text-indigo-700'>
									{` ${resumeCount} ${
										resumeCount === 1 ? 'document' : 'documents'
									}.`}
								</span>{' '}
							</p>
						</h2>
					</div>
					<div className='mt-4'>
						<Link to='/ResumeBuilder'>
							<button className='flex items-center justity-center rounded-md bg-gradient-to-r from-violet-500 to-violet-600 text-white px-4 py-2 text-sm'>
								<ion-icon name='add'></ion-icon>
								&nbsp;Create New Resume&nbsp;&nbsp;
							</button>
						</Link>
					</div>
					<div className=' text-sm md:text-lg'>
						<nav className='mt-4 border-b-2 border-gray-300 w-full'>
							<div className='flex items-center justify-start'>
								<NavLink to='/' isActive={location.pathname === '/Dashboard'}>
									{isMediumScreen ? 'All' : 'All Documents'}
								</NavLink>
								<NavLink
									to='/resume'
									isActive={location.pathname === '/resume'}
								>
									Resume
								</NavLink>
								<NavLink
									to='/cover-letters'
									isActive={location.pathname === '/cover-letters'}
								>
									Cover letter
								</NavLink>
							</div>
						</nav>
					</div>
				</div>
				{/* Resumecontainer div */}
				<div className='mt-2 '>
					{userData.resumes.map((resume) => (
						<div key={resume.id} className='container mx-auto'>
							{console.log('resume ka id -----------', resume.resumeId)}
							<div className='p-4 bg-emerald-50 border border-blue-100 rounded-lg shadow-md text-gray-500 mb-3'>
								<div className='flex items-center justify-between'>
									<h1 className='font-semibold text-violet-800 text-sm'>
										RESUME{' '}
										<span className='text-violet-800 font-semibold'>
											#{resume.resumeId}
										</span>
									</h1>
									<button
										onClick={() => handleDelete(resume.id)}
										className='handle_delete_button'
									>
										<FontAwesomeIcon className='' icon={faTrash} />
									</button>
								</div>
								<div className='mt-1 mb-2'>
									<p>{resume.resumeName}</p>
									<p className='mt-1 text-[12px] text-gray-400'>
										Last edited {formatTimeDifference(resume.lastEditedDate)},
										Created {formatTimeDifference(resume.createdDate)}
									</p>
								</div>
								<div className=' '>
									<div className='mt-2 flex gap-4'>
										<button className='flex items-center justity-center  rounded-md bg-gradient-to-r from-green-600 to-green-700 text-white px-3 py-1 text-sm'>
											Continue Editing
										</button>
										<button className='flex items-center justity-center  rounded-md border-2 border-solid border-violet-500 text-violet-700 px-3 py-1 text-sm'>
											<FontAwesomeIcon icon={faCircleArrowDown} />
											&nbsp;Download
										</button>
									</div>
								</div>
							</div>
							{showConfirmation && (
								<DeleteConfirmation
									onConfirm={handleConfirmDelete}
									onCancel={handleCancel}
								/>
							)}
						</div>
					))}
				</div>
				<New />
			</div>
		</div>
	);
}
const NavLink = ({ to, isActive, children }) => {
	return (
		<Link
			to={to}
			className={`relative px-4 mr-4 ${
				isActive
					? 'text-indigo-800 border-b-2 border-indigo-800'
					: 'text-gray-600'
			}`}
		>
			{children}
		</Link>
	);
};

export default Dashboard;
