import React from 'react';
import New from './New';
import aboutlogo from '../assets/about_main.jpg';
import blob from '../assets/Stacked_blob.png';
import 'animate.css';

function About() {
	return (
		<div>
			<div className='px-4 py-20 md:px-6 md:py-26'>
				<div className='flex items-center justify-center animate__animated  animate__fadeInLeft'>
					<img src={blob} alt='' className='h-64 md:h-72' />
					<img
						className='w-48 overflow-hidden absolute rounded-full h-48 md:h-56 md:w-56 shadow-md'
						src={aboutlogo}
						alt=''
					/>
				</div>
				<div className='text-center font-salbo text-3xl font-light text-indigo-700 animate__animated animate__fadeInUp'>
					<h1>
						We're a tech-savvy team of 4 who are focussed on revolutionizing
						resume creation and sharing with modern innovation.
					</h1>
					<button className='rounded-3xl bg-gradient-to-r from-indigo-700 to-indigo-900  text-white px-7 py-3 mt-5 text-sm'>
						Interested in Joining the Team?
					</button>
				</div>
			</div>
			<New />
		</div>
	);
}

export default About;
