import React, { useState } from 'react';
import New from './New';
import { Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db, doc, setDoc, collection, addDoc } from './firebase';
import { useNavigate } from 'react-router-dom';

function Register() {
	const [value, setValue] = useState({
		name: '',
		email: '',
		password: '',
	});
	const navigate = useNavigate();
	const [errorMsg, setErrorMsg] = useState('');
	const [submitButtonDisable, setSubmitButton] = useState(false);

	const handleSubmission = async () => {
		if (!value.name || !value.email || !value.password) {
			setErrorMsg('Please fill in all the values');
			return;
		}
		setErrorMsg('');
		console.log(value);
		setSubmitButton(true);

		try {
			const res = await createUserWithEmailAndPassword(
				auth,
				value.email,
				value.password
			);
			const user = res.user;
			const userEmail = user.email;
			const currentDate = new Date();

			// Update user profile with name
			await updateProfile(user, { displayName: value.name });

			// Create a document in the 'userBase' collection with the user's UID
			const userDocRef = doc(db, 'userBase', userEmail);
			await setDoc(userDocRef, {
				resumeCount: 1,
				userName: user.displayName,
				// You can add more user details here if needed
			});

			// Create 'resumes' collection under the user document
			const resumeCollectionRef = collection(userDocRef, 'resumes');
			await addDoc(resumeCollectionRef, {
				// Add more fields as needed
				createdDate: currentDate,
				email: userEmail,
				jobTitle: '',
				lastEditedDate: currentDate,
				linkedin: '',
				location: '',
				phone: '',
				resumeId: '1',
				resumeName: user.displayName.replace(/\s+/g, '') + '_Resume',
				userName: user.displayName,
			});

			setSubmitButton(false);
			navigate('/Dashboard');
		} catch (err) {
			setSubmitButton(false);
			setErrorMsg(err.message);
			console.error('Error:', err.message);
		}
	};

	return (
		<div>
			<div className='py-[150px] flex items-center justify-center text-4xl '>
				<div>
					<h1 className='text-lg  text-center text-indigo-800'>
						Create Your Account
					</h1>
					<div className='flex flex-col mt-5 gap-4 px-5'>
						<input
							className='border w-[350px] px-3 py-4 rounded-md text-sm font-light text-gray-600 border-none focus:outline-none bg-blue-50'
							type='text'
							name='usrname'
							placeholder='Your Name*'
							onChange={(event) =>
								setValue((prev) => ({ ...prev, name: event.target.value }))
							}
						/>

						<input
							className='border w-[350px] px-3 py-4 rounded-md text-sm font-light text-gray-600 border-none focus:outline-none bg-blue-50'
							type='text'
							name='usremail'
							placeholder='Email Address*'
							onChange={(event) =>
								setValue((prev) => ({ ...prev, email: event.target.value }))
							}
						/>
						<input
							className='border w-[350px] px-3 py-4 rounded-md text-sm font-light text-gray-600 border-none focus:outline-none bg-blue-50'
							type='password'
							name='usrpasswd'
							placeholder='Password*'
							onChange={(event) =>
								setValue((prev) => ({ ...prev, password: event.target.value }))
							}
						/>
						<div className='flex items-center gap-2 mt-2'>
							<input
								className='w-4 h-4 rounded-full'
								type='checkbox'
								name='usrname'
								placeholder='Password*'
							/>
							<p className='text-sm'>
								I agree to{' '}
								<span className='text-blue-900 font-semibold'>
									Terms of Service
								</span>{' '}
								and
								<span className='text-blue-900 font-semibold'>
									{' '}
									Privacy Policy
								</span>
							</p>
						</div>

						<div className='mt-1'>
							<button
								onClick={handleSubmission}
								disabled={submitButtonDisable}
								className={`rounded-lg md:rounded-md py-4 text-sm ${
									submitButtonDisable
										? 'bg-gray-400 text-white w-[350px] py-4 text-sm'
										: 'bg-gradient-to-r from-indigo-700 to-indigo-900 text-white w-[350px] py-4 text-sm'
								}`}
							>
								Create An Account
							</button>
							<div className='flex items-center justify-center'>
								<div className='text-sm mt-4'>
									Already have an account?{' '}
									<Link className='text-indigo-800 font-semibold' to='/Login'>
										Sign In
									</Link>
								</div>
							</div>
							<div className='flex items-center justify-center px'>
								<h1 className='text-red-500 text-sm mt-2'>{errorMsg}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<New />
		</div>
	);
}

export default Register;
