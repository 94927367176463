import React, { useState, useEffect } from 'react';
import { collection, getDocs, db } from './firebase';
import { toPdf } from 'html2pdf.js';
import ReactHtmlParser from 'html-react-parser';

const ExportResume = ({ user }) => {
	const [resumeData, setResumeData] = useState([]);

	useEffect(() => {
		// Fetch resume data from Firestore
		const fetchData = async () => {
			const resumeCollection = collection(db, 'resumes');
			const snapshot = await getDocs(resumeCollection);
			const data = snapshot.docs.map((doc) => doc.data());
			setResumeData(data);
		};

		fetchData();
	}, [db]);

	const generatePDF = () => {
		// Convert resume data to HTML
		const resumeHTML = `<div>${resumeData
			.map((resume) => `<p>${resume}</p>`)
			.join('')}</div>`;

		// Convert HTML to PDF
		toPdf(ReactHtmlParser(resumeHTML))
			.outputPdf()
			.then((pdfBlob) => {
				// Create a Blob URL and trigger download
				const url = URL.createObjectURL(pdfBlob);
				const a = document.createElement('a');
				a.href = url;
				a.download = 'resume.pdf';
				a.click();
				URL.revokeObjectURL(url);
			});
	};

	return (
		<div>
			<button onClick={generatePDF}>Download Resume as PDF</button>
		</div>
	);
};

export default ExportResume;
