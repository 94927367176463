import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/main.png';
import google from '../assets/google.png';
import facebook from '../assets/facebook.png';
import spotify from '../assets/spotify.png';
import amazon from '../assets/amazon.png';
import logo1 from '../assets/1.png';
import logo2 from '../assets/main.png';
import { Link } from 'react-router-dom';

import './Home.css';

import 'animate.css';
import New from './New';

function Home() {
	return (
		<div className='min-h-screen md:flex bg-gradient-to-r from-blue-50 to-blue-200 h-screen'>
			<div className='md:w-1/3 md:order-2 text-center py-6 mt-14 md:mt-24 flip-container mx-auto'>
				<div className='flipper h-[170px] w-[170px] md:h-[350px] md:w-[350px] md:pr-8 md:left-0 '>
					{/* Existing image (logo) */}
					<img className='front ' src={logo1} alt='' />
					{/* Back of the bill (logo2) */}
					<img className='back ' src={logo2} alt='Back of the bill' />
				</div>
			</div>

			<div className='md:w-2/3 md:order-1 text-center mt-[-8px] md:px-16 md:mt-[180px] md:text-left'>
				<h1 className=' text-3xl md:text-6xl font-light px-4 text-gray-600 md:leading-[70px] md:tracking-wide'>
					Only 2% of resumes make it past the first round.
					<span className=' text-3xl md:text-6xl  text-indigo-700 px-2'>
						Be part of the top-tier candidates.
					</span>
				</h1>
				<div className='mt-4 md:mt-10 mb-2 md:flex justify-left md:px-5 gap-8'>
					<button className='rounded-full md:rounded-md  bg-gradient-to-r from-indigo-700 to-indigo-900  text-white px-11 py-3 text-sm md:text-md'>
						<Link to='/Register'>Build Your Resume</Link>
					</button>
					<button className='hidden md:inline-block rounded-md bg-gradient-to-r from-green-600 to-green-800  text-white px-9 py-3 text-sm md:text-md'>
						Get Your Online Portfolio Free
					</button>
				</div>
				<div className='px-6 text-gray-600 font-bold flex flex-col mt-4 md:mt-10 items-center md:items-start'>
					<h1 className='mb-0 font-bold'>Loved by the Interviewers at</h1>
					<div className='flex items-center justify-center xs:items-center xs:justify-center py-3 gap-5'>
						<img className='h-[20px] w-[60px]' src={google} alt='' />
						<img
							className='h-[17px] w-[80px] mt-[-4px] '
							src={facebook}
							alt=''
						/>
						<img className='h-[19px] w-[65px] mt-[-4px]' src={spotify} alt='' />
						<img className='h-[20px] w-[65px] mt-[5px]' src={amazon} alt='' />
					</div>
				</div>
			</div>

			<footer></footer>
			<New />
		</div>
	);
}

export default Home;

{
	/* <div className='min-h-screen md:flex'>
			<div className='md:w-1/2 md:order-2 bg-blue-200 text-center py-6'>
				<img className='mx-auto h-[360px] w-[360px]' src={logo} alt='' />
			</div>
			
			<div className='md:w-1/2 md:order-1 bg-red-200 text-center'>
				<h1 className='font-salbo text-3xl font-light px-4 text-gray-600'>
					Resume Builder which empowers you to get hired at the
					<span className='font-salbo text-3xl font-bold text-indigo-700 px-2'>
						#Top Companies
					</span>
				</h1>
				<div className='mt-4 mb-8'>
					<button className='rounded-3xl bg-gradient-to-r from-indigo-700 to-indigo-900  text-white px-9 py-3 text-sm'>
						Build Your Resume
					</button>
				</div>
			</div>

			<footer></footer>
		</div> */
}
