import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

function CollapsableItems() {
	return (
		<div className='px-6 py-2 bg-[#fafbfd]'>
			<div className='text-lg'>Education</div>
			<div className='text-[13px] mt-1'>
				List the latest 1 or 2 degrees you have - mentioning your GPA is not
				mandatory
			</div>
			<div className='py-2 mt-2'>
				<button className='border border-gray-500 text-left w-full px-4 py-2 bg-gray-100 rounded-md text-gray-400 flex justify-between items-center'>
					<div>
						<div className='text-md'>Degree or Field of Study</div>
						<div className='text-sm'>School or University</div>
					</div>
					<div>
						<FontAwesomeIcon
							icon={faEllipsisVertical}
							className='text-gray-500 text-2xl'
						/>
					</div>
				</button>
			</div>
		</div>
	);
}

export default CollapsableItems;
