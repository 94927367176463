import React from 'react';
import New from '../components/New';

function Signin() {
	return (
		<div>
			<div className='py-[400px] flex items-center justify-center text-4xl'>
				{' '}
				Page Coming Soon!
			</div>
			<New />
		</div>
	);
}

export default Signin;
